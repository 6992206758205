import { graphql, Link } from 'gatsby';
import React from 'react';
import { useLayoutEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import styled, { css } from 'styled-components';

import logo from '../assets/annalaumi.gif';
import closeButtonSrc from '../assets/close-btn.svg';
import { BaseLayout } from '../components/base-layout';

const Container = styled.div`
  margin: 12vh 16vw;
  @media (max-width: 768px) {
    margin: 12vh 0;
  }
  color: rgb(0, 0, 176);
`

const ProjectName = styled.h1`
  font-size: 1.9rem;
  font-family: "Josefin Sans", sans-serif;
  margin-bottom: 2.5rem;
  @media (max-width: 768px) {
    margin-left: 12vw;
    font-size: 1.5rem;
    max-width: 65vw;
  }
`

const dimensionConstraints = css`
  max-height: 100vh;
  max-width: 60vw;
  @media (max-width: 768px) {
    max-width: 100vw;
  }
`

const margin = css`
  margin: 0 0 2rem 0;
`
const MarkdownContent = styled.div`
  > .responsive-video-wrapper {
    width: 60vw;
  }
  > .responsive-video-wrapper-small {
    width: 30vw;
  }
  > div > .responsive-video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0px;
    height: 0;
    overflow: hidden;
    width: 60vw;

    @media (max-width: 768px) {
      width: 100vw !important;
    }
    > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    > video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-position: left;
    }
  }

  > p {
    color: rgb(0, 0, 176);
    font-size: 1.1rem;
    max-width: 40vw;
    ${margin};
    img {
      ${dimensionConstraints};
      @media (max-width: 768px) {
        margin-left: -12vw;
      }
    }
    @media (max-width: 768px) {
      margin-left: 12vw;
      max-width: 70vw;
      font-size: 1rem;
    }
  }
  > img {
    ${dimensionConstraints};
    ${margin};
  }

  .gatsby-image-wrapper > img {
    height: 98% !important;
  }

  > .gatsby-image-wrapper {
    img {
      object-fit: contain !important;
      object-position: center left !important;
      ${dimensionConstraints};
    }
    ${dimensionConstraints};
    ${margin};
  }
`

const Logo = styled.img`
  position: fixed;
  top: 2vh;
  left: 70vw;
  width: 20vw;
  z-index: 10;
  @media (max-width: 768px) {
    display: none;
  }
`

const CloseButton = styled(Link)`
  position: fixed;
  top: 11.5vh;
  left: 9vw;
  z-index: 10;
  @media (max-width: 768px) {
    left: unset;
    right: 10vw;
    top: 12vh;
  }
`

const CloseImage = styled.img`
  width: 2rem;
  @media (max-width: 768px) {
    width: 2rem;
  }
`
function hidePreviews(wrapper) {
  try {
    const imageWrappers = wrapper.getElementsByClassName("gatsby-image-wrapper")
    for (let i = 0; i < imageWrappers.length; i++) {
      const wrapper = imageWrappers.item(i)
      let preview, picture
      const childNodes = wrapper.childNodes
      for (let j = 0; j < childNodes.length; j++) {
        const node = wrapper.childNodes[j]
        if (node.tagName === "IMG") {
          preview = node
        }
        if (node.tagName === "PICTURE") {
          picture = node.getElementsByTagName("img").item(0)
        }
      }

      const hideImage = () =>
        preview.setAttribute(
          "style",
          preview.getAttribute("style") + ";opacity:0"
        )
      if (picture.complete) {
        hideImage()
      } else {
        picture.addEventListener("load", () => hideImage())
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export default ({ data }) => {
  const project = data.allStrapiProject.edges[0].node

  const markdownRef = useRef()
  useLayoutEffect(() => {
    if (markdownRef.current) {
      hidePreviews(markdownRef.current)
    }
  }, [])
  return (
    <BaseLayout>
      <Helmet>
        <title>
          {project.name} - Anna Laumer - communication & motion design{" "}
        </title>
        <meta name="robots" content="index, follow" />
        {project.google_text ? (
          <meta name="description" content={project.google_text} />
        ) : null}
      </Helmet>
      <Container>
        <CloseButton src={"/"}>
          <CloseImage src={closeButtonSrc} />
        </CloseButton>
        {project.no_logo === true ? null : <Logo src={logo} />}
        <ProjectName> {project.name}</ProjectName>
        <MarkdownContent
          ref={markdownRef}
          dangerouslySetInnerHTML={{
            __html: project.childStrapiProjectMarkdown.childMarkdownRemark.html,
          }}
        />
      </Container>
    </BaseLayout>
  )
}

export const pageQuery = graphql`
  query ProjectQuery($id: String) {
    allStrapiProject(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          name
          no_logo
          google_text
          childStrapiProjectMarkdown {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
